//import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './route';
import reportWebVitals from './reportWebVitals';
// import { MemoryRouter } from 'react-router'

// import Home from './home';


//css
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<>


    <App/>
    {/* <Home/> */}
    </>
);

reportWebVitals();
