//axios calls
import {LogOut} from "../services/auth-service";
import {GetStudentProperties, GetQuestionReview, SeeDoctor} from "../services/user-service"

//React
import React, { useState, useEffect } from "react";
//css
import './student.css';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//navigate
import { NavigateFunction, useNavigate } from 'react-router-dom';

//socketio
import {io} from 'socket.io-client'
import { SOCKET_URL } from '../services/user-service';

//crypt to localstorage
import  secureLocalStorage  from  "react-secure-storage";

//content switch
let contentid = "item0";
//teacher click start
let start:boolean = false;
//always check token avaliable or not
const token = secureLocalStorage.getItem("accessToken");
const userid:string|undefined = secureLocalStorage.getItem("userid")?.toString();
const roomname = secureLocalStorage.getItem("school_class")

//check student get a valid role or not
let NotFirstRandom = secureLocalStorage.getItem("NotFirstRandom");

//export function start
const BoardStudent: React.FC = () => {

  const showSidebar = () => {
    if(token === null){
      LogOut();
    }
    showNavbar('stheader-toggle','stnav-bar','stbody-pd','stheader')
  }

  //show bar function
  const showNavbar = (toggleId:string, navId:string, bodyId:string, headerId:string) =>{
    const toggle = document.getElementById(toggleId),
    nav = document.getElementById(navId),
    bodypd = document.getElementById(bodyId),
    headerpd = document.getElementById(headerId)
  
  // Validate that all variables exist
    if(toggle && nav && bodypd && headerpd){
        // show navbar
        nav.classList.toggle('stshow')
        // change icon
        toggle.classList.toggle('bi-x')
        // add padding to body
        bodypd.classList.toggle('stbody-pd')
        // add padding to header
        headerpd.classList.toggle('stbody-pd')
    }
  }

  //switch contents
  const contentSwitch = (contentid:string) => {
    if(token === null){
      LogOut();
    }
    if(contentid === 'item0'){
      clearQuesAnsOption();
      if(start){
        return waitinggamehtml;
      }
      if(NotFirstRandom){
        return afterRanjoingamehtml;
      }else{
        return beforeRanjoingamehtml;
      }
    }
    else if (contentid === 'item1'){
      return stdhistoryhtml;
    }
  }
  
    /*===== LINK ACTIVE =====*/
  const toggleClass = (itemid:string) => {
    const linkColor = document.querySelectorAll('.stnav_link')
    if(linkColor){
      linkColor.forEach(l=> l.classList.remove('stactive'))
    }
    const item = document.getElementById(itemid)
    if(item){
      item.classList.add('stactive')
    }
    contentid = itemid;
  };

  const initialProperties = {
    game_role: '',
    iq: 0,
    eq: 0,
    asset: 0,
    pressure: 0,
    adult_status: null,
    career: null,
    residence: null,
  }

  const initialQuesAns = {
    lesson_num : -1,
    question_num: -1,
    questions: '',
    options : [''],
    answers: -1,
  }
  
  const [userProperties, setUserProperties] = useState(initialProperties);
  const [userHistoryQuesAns, setUserHistoryQuesAns] = useState([initialQuesAns]);

  //navi function
  let navigate: NavigateFunction = useNavigate();

  //through this method trigger to show the userdata
  const [teacherstart, setteacherstart] = useState(false)
  const [isRoleOK, setisRoleOK] = useState(false)

  //history handle selected question
  const handleSelectQuesAns = (QuesAnsID:string, i:number) => {
    const QuesAnsItem = document.getElementById(QuesAnsID)
    if(QuesAnsItem){
      if(QuesAnsItem.classList.contains("stdQuesAnsActive")){
        QuesAnsItem.classList.remove('stdQuesAnsActive')
        handleSelectQuesAnsOption(-1);
        return;
      }
    }
    const QuesAns = document.querySelectorAll('.stdQuesAns')
    if(QuesAns){
      QuesAns.forEach(l=> l.classList.remove('stdQuesAnsActive'))
    }

    if(QuesAnsItem){
      QuesAnsItem.classList.add('stdQuesAnsActive')
    }

    handleSelectQuesAnsOption(i)
  }
  
  const handleSelectQuesAnsOption = ( i:number) => {
    let htmlStr = '';
    const stdhistoryAnsItem = document.getElementById("stdhistoryAnsItem")
    if(i < 0){
      clearQuesAnsOption();
      return;
    }
    if(stdhistoryAnsItem){
      let optionid = 0;
      userHistoryQuesAns[i].options.forEach(function (item) {
          htmlStr = htmlStr + `
            <div id = "choice${optionid}">${item}</div>
          `;
          optionid++;
      });
      stdhistoryAnsItem.innerHTML = htmlStr;
    }
    const stdANS = document.getElementById("choice" + userHistoryQuesAns[i].answers)
    if(stdANS){
      stdANS.classList.add("stdHistoryANS")
    }
  }
  
  const clearQuesAnsOption = ()=> {
    const stdhistoryAnsItem = document.getElementById("stdhistoryAnsItem")
    if(stdhistoryAnsItem){
      stdhistoryAnsItem.innerHTML = '';
      return;
    }
  }

  useEffect(() => {
    if (teacherstart) {
      navigate("/")
      window.location.reload();        
    }
      GetStudentProperties().then(
        (response) => {
          //!!!!!temp method to deal with JWT expired force LogOut!!!!!!
          if(response.message === 'Request failed with status code 500'){
            LogOut();
          }
          if(response.data.game_role !== null){
            secureLocalStorage.setItem("NotFirstRandom",true)
            NotFirstRandom = secureLocalStorage.getItem("NotFirstRandom")
            setUserProperties(response.data)
          }
          else{
            secureLocalStorage.setItem("NotFirstRandom",false)
            NotFirstRandom = secureLocalStorage.getItem("NotFirstRandom")
          }
        })

    if(isRoleOK){
      GetStudentProperties().then(
        (response) =>{
          if(response){
            if(response.data.game_role){
              Rebirth();
              secureLocalStorage.setItem("NotFirstRandom",true)
              window.location.reload();
              const identity = document.getElementById("identity");
              if(identity){
                identity.classList.remove("stdimagecontainerbeforerandom")
                identity.classList.remove("stdimagecontainerafterrandom")
                identity.classList.remove("stdimagecontainerabort")
                identity.classList.add(identityClass(response.data.game_role))                
              }
              const propertybox  = document.getElementById("propertybox");
              if(propertybox){
                propertybox.innerHTML = `
                    <div><i class='bi bi-lightbulb icon '></i>IQ: ${response.data.iq}</div>
                    <div><i class='bi bi-emoji-smile-fill icon'></i>EQ: ${response.data.eq}</div>
                    <div><i class='bi bi-cash-coin icon '></i>Money: ${response.data.asset}</div>
                    <div><i class='bi bi-emoji-frown-fill icon'></i>Stress: ${response.data.pressure}</div>
                `
              }

              const stdgamestartbtn = document.getElementById("stdgamestartbtn");
              if(stdgamestartbtn){
                stdgamestartbtn.innerHTML = `<a href = '#' id = "GameStart">Game Start</a>`
                stdgamestartbtn.addEventListener('click',function(){GameStartBtn(true)})
              }
            }
            else{
              const identity = document.getElementById("identity");
              if(identity){
                identity.classList.remove("stdimagecontainerbeforerandom")
                identity.classList.remove("stdimagecontainerafterrandom")
                identity.classList.add("stdimagecontainerabort")
              }
              const stdrolerandombtn = document.getElementById("stdrolerandombtn");
              if(stdrolerandombtn){
                stdrolerandombtn.classList.add("stdrolerandombtn");
                stdrolerandombtn.innerHTML = `<a href = '#' id = "Rebirth" }>Rebirth!</a>`
                stdrolerandombtn.addEventListener('click',function(){Rebirth()});
              }
            }
          }
        })
    }
    
    let len = 0;
     GetQuestionReview().then(
      (data) =>{
        if(data){
          userHistoryQuesAns.pop();
          len = data.question.length;
          for(let i = 0; i < len; ++i){
            let tempques:string = data.question[i].statement;
            let anslen = data.option[i].length;
            let tempopt:string[] = [];
            let templessonnum:number = data.question[i].lesson_num
            let tempquestionnum:number = data.question[i].question_num
            for(let j = 0; j < anslen; ++j){
              tempopt[j] = data.option[i][j].statement;
            }
            let tempAns = data.answer[i];
            userHistoryQuesAns.push({
                lesson_num : templessonnum,
                question_num : tempquestionnum,
                questions : tempques,
                options: tempopt,
                answers:tempAns
            });
          }
        }
        console.log(userHistoryQuesAns)
      }
    ).catch((error) => {
      userHistoryQuesAns.pop()
    });
  }, [teacherstart, isRoleOK]);

  //socket.io
  let socket:any = null    
  const [currentSocket, setCurrentSocket] = useState(socket)

  useEffect(() => {
    async function connect () {
        socket = io(SOCKET_URL);   
        setCurrentSocket(socket)
    }
    connect();
    socket.on('connect', () => {
      socket.emit("connected", 'hi')
      socket.emit('join-room', roomname, 'student')
    })

    //notify teacher on teacher's connection
    socket.on('teacher-connected', (socketid:any) => {
      if (start===true)
        socket.emit("student-joined", roomname, userid, socketid)
    });

    //force logout
    socket.on('duplicated-connection', () => {
      console.log('duplicated-connection')
      LogOut()
    })

    //teacher start session
    socket.on('start', () => {
      setteacherstart(true)
      secureLocalStorage.setItem("teacherstart",true);
    })

    //Teacher random the role
    socket.on('role-get', () => {
      if(!isRoleOK){
        setisRoleOK(true)
      }
    })    
    //teacher post new question
    socket.on('new-question-by-teacher', (response:any) => {
      secureLocalStorage.setItem("options", JSON.stringify(response.options))
      secureLocalStorage.setItem("dValuescopy", JSON.stringify(response.ansdValues))
      secureLocalStorage.setItem("ansdValue", JSON.stringify(response.ansdValues[0]))

      //generate random number
      const min = 0;
      const max = response.options.length;
      const rand = Math.floor(min + Math.random() * (max - min));

      let content:any[]=[]
      let arraylength = response.options.length
      response.options.forEach((value:any, i:any) => {
        content[i] = response.options[(rand+i)%arraylength]        
      })
      secureLocalStorage.setItem("question", response.question)
      secureLocalStorage.setItem("answerContent", JSON.stringify(content))
      secureLocalStorage.setItem("QuestionScreen", JSON.stringify(true))
      secureLocalStorage.setItem("ResultScreen", JSON.stringify(false))
    })
      
  }, [socket]);
  
  //START GAME BUTTON
  const GameStartBtn = (thisstart:boolean) => {
    start = thisstart;
    if(thisstart===true) 
      currentSocket.emit("student-joined", roomname, userid)
    else
      currentSocket.emit("student-left")      
    
    secureLocalStorage.removeItem("teacherstart")
    if(token === null){
      LogOut();
    }
  };

  //role random BUTTON
  const Rebirth = () => {
    const identity = document.getElementById("identity");
    if(identity){
      identity.classList.remove("stdimagecontainerbeforerandom")
      identity.classList.remove("stdimagecontainerabort")
      identity.classList.add("stdimagecontainerafterrandom")

      if(identity.classList.contains("stdimagecontainerafterrandom")){
        const stdrolerandombtn = document.getElementById("stdrolerandombtn");
        if(stdrolerandombtn){
          stdrolerandombtn.classList.remove("stdrolerandombtn");
          stdrolerandombtn.innerHTML = '';
        }
      }
    }
    setisRoleOK(false)
  }

  //identity classname selector
  const identityClass = (game_role:string) =>{
    switch(game_role){
      case "upper":
        return "stdimagecontainerupper";
      case "middle":
        return "stdimagecontainermiddle";
      case "lower":
        return "stdimagecontainerlower";
      case "orphan":
        return "stdimagecontainerorphan";
      default:
        return "stdimagecontainerbeforerandom";
    }
  }
  
const beforeRanjoingamehtml = 
<div className="joingamecontainer">
  <div className="stdcard-container"> 
    <div className="stdcard-identitycontainer">
      <div className="stdimagecontainerbeforerandom" id = "identity"></div>
    </div>
    <div className="propertybox" id = "propertybox">
      <div><i className='bi bi-lightbulb icon IQicon '></i>IQ:</div>
      <div><i className='bi bi-emoji-smile-fill icon EQicon'></i>EQ:</div>
      <div><i className='bi bi-cash-coin icon Asseticon'></i>Money:</div>
      <div><i className='bi bi-emoji-frown-fill icon Pressureicon'></i>Stress:</div>
    </div>
    <div className="stdrolerandombtn" id="stdrolerandombtn">
      <a href = '#' id = "Rebirth" onClick={Rebirth}>Rebirth!</a>
    </div>
    <div className="stdgamestartbtn" id="stdgamestartbtn">
      {/* <a href = '#' onClick={() => GameStartBtn(true)}>Game Start</a> */}
    </div>
  </div>
  <div className="stdwavecontainer">
      <div className="stdline stdline-1"> 
        <div className="stdwave stdwave1"></div>
      </div>
      <div className="stdline stdline-2"> 
        <div className="stdwave stdwave2"></div>
      </div>
      <div className="stdline stdline-3"> 
        <div className="stdwave stdwave3"></div>
      </div>
    </div>
</div>

const afterRanjoingamehtml = 
<div className="joingamecontainer">
  <div className="stdcard-container"> 
    <div className="stdcard-identitycontainer">
      <div className={identityClass(userProperties.game_role)} id = "identity"></div>
    </div>
    <div className="propertybox" id="propertybox">
      <div><i className='bi bi-lightbulb icon IQicon'></i> <span> <i>IQ: {userProperties.iq}</i></span></div>
      <div><i className='bi bi-emoji-smile-fill icon EQicon'></i> <span> <i>EQ: {userProperties.eq}</i></span></div>
      <div><i className='bi bi-cash-coin icon Asseticon'></i><span><i>Money: {userProperties.asset}</i></span></div>
      <div><i className='bi bi-emoji-frown-fill icon Pressureicon'></i> <span> <i>Stress: {userProperties.pressure}</i></span></div>
      {userProperties.adult_status ? <div><i className='bi bi-mortarboard-fill icon educationicon'></i><span>學歷： <i>{userProperties.adult_status}</i></span></div> : null}
      {userProperties.career ? <div><i className='bi bi-bag-fill icon careericon'></i><span>職業： <i>{userProperties.career} </i></span></div> : null}
      {userProperties.residence ? <div><i className='bi bi-house-fill icon residenceicon'></i><span>住所： {userProperties.residence}</span></div> : null}
    </div>
    {/* <div className="statusbox" id="statusbox">
      
    </div> */}
    <div className="stdgamestartbtn">
      <a href = '#' onClick={() => GameStartBtn(true)}>Game Start</a>
    </div>
    {userProperties.asset >= 100 && userProperties.pressure >= 15 ? <div className="stdseedoctorbtn"><a href='#' onClick={() => SeeDoctor()}>See a doctor</a></div> : null}
  </div>
  <div className="stdwavecontainer">
      <div className="stdline stdline-1"> 
        <div className="stdwave stdwave1"></div>
      </div>
      <div className="stdline stdline-2"> 
        <div className="stdwave stdwave2"></div>
      </div>
      <div className="stdline stdline-3"> 
        <div className="stdwave stdwave3"></div>
      </div>
    </div>
</div>
  
  const waitinggamehtml = 
  <div className="joingamecontainer">
    <div className="stdmid-text">
      <h1 className="stdtitle">
        <span className="stdtitle-word stdtitle-word-1">Waiting on </span>
        <span className="stdtitle-word stdtitle-word-2">teacher to </span>
        <span className="stdtitle-word stdtitle-word-3">start </span>
        <span className="stdtitle-word stdtitle-word-4">the game</span>
      </h1>
    </div>
    <div className="stloader"></div>
    <div className="stdreturnbtn">
      <a href = '#' onClick={() => GameStartBtn(false)}><i className="bi bi-arrow-left-square-fill icon"></i></a>
    </div>
    <div className="stdwavecontainer">
      <div className="stdline stdline-1"> 
        <div className="stdwave stdwave1"></div>
      </div>
      <div className="stdline stdline-2"> 
        <div className="stdwave stdwave2"></div>
      </div>
      <div className="stdline stdline-3"> 
        <div className="stdwave stdwave3"></div>
      </div>
    </div>
  </div>

  const stdhistoryhtml = 
  <div className="stdhistorycontainer">
    <div className="stdhistoryinnercontainer">
      <div className="stdhistoryquestion">
        <div className="stdhistoryquestionHeader">
          <h2>Questions</h2>
        </div>
        <div className="stdhistoryquestionItem" id = "stdhistoryquestionItem">  
        {userHistoryQuesAns.map((userHistoryQuesAns, i) => (
          <div key = {i} className="stdQuesAns" id = {"QuesAns" + i} onClick={()=>handleSelectQuesAns("QuesAns" + i, i)}>
            <span>Lesson: {userHistoryQuesAns.lesson_num} </span> 
            <span> Question: {userHistoryQuesAns.question_num}</span>
            <p>{userHistoryQuesAns.questions}</p>
          </div>
        ))}
        </div>
      </div>
      <div className="stdhistoryanswer">
        <div className="stdhistoryanswerHeader">
          <h2>Your Answers</h2>
        </div>
        <div className="stdhistoryanswerItem" id = "stdhistoryAnsItem">

        </div>
      </div>
    </div>
    
    <div className="stdwavecontainer">
      <div className="stdline stdline-1"> 
        <div className="stdwave stdwave1"></div>
      </div>
      <div className="stdline stdline-2"> 
        <div className="stdwave stdwave2"></div>
      </div>
      <div className="stdline stdline-3"> 
        <div className="stdwave stdwave3"></div>
      </div>
    </div>
  </div>

  return (
      <div className="stbody" id = "stbody-pd">
        <div className="stheader" id="stheader">
            <div className="stheader_toggle" onClick={showSidebar}><i className='bi bi-list sticon' id="stheader-toggle"></i> </div>
            <div className="stheadertext"> Welcome, {userid} </div>
            {/* <div class="header_img"> <img src="..." alt=""> </div> */}
        </div>
        <div className="stl-navbar" id="stnav-bar">
            <nav className="stnav">
                <div> 
                    <div > 
                      <a href = '#' id = "item0"className="stnav_link stactive" onClick={() => toggleClass("item0")}> <i className='bi bi-joystick stnav_icon'></i> <span className="stnav_name">Join Game</span> </a>
                      <a href = '#' id = "item1"className="stnav_link" onClick={() => toggleClass("item1")}> <i className='bi bi-bar-chart-line-fill stnav_icon'></i> <span className="stnav_name">History </span> </a>
                    </div>
                </div> 
                <a href = '#' id = "item3" className="stnav_link" onClick={LogOut}> <i className='bi bi-box-arrow-left stnav_icon'></i> <span className="stnav_name">SignOut</span> </a>
            </nav>
        </div>
        <div className = "stcontent" id = "content">
          {contentSwitch(contentid)}
        </div>
      </div>
  );
};

export default BoardStudent;