import './Anser.css'

//mainly use for display the options in the student's mc page
function Answer({...props}) {
    const {onClick, content} = props;
    return (
      <div className={"answer-field"} onClick={onClick}>
        <h2 className='h2'>{content}</h2>
      </div>
    )
  }
  
  export default Answer