//axios calls
import {LogOut} from "../services/auth-service";
import {GetQuestionReview} from "../services/user-service"

//React
import React, { useState, useEffect }from "react";
//css
import './TeacherReviewQuestion.css';

//crypt to localstorage
import  secureLocalStorage  from  "react-secure-storage";

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//chart
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

//JWT
const token = secureLocalStorage.getItem("accessToken");

//pie chart color bank
const PIECHARTCOLORS = ['#4285F4', '#EA4335', '#FBBC05', '#34A853', '#befb46', '#ff4293', '#ff8e42', '#b514ff'];

//the piechartdata[0] is tesing data and will be default display, set values to all zeros to hide the chart.
const piechartdata = [[
  { name: 'Group A', value: 0 },
  { name: 'Group B', value: 0},
  { name: 'Group C', value: 0 },
  { name: 'Group D', value: 0 },
  { name: 'Group E', value: 0 },
  { name: 'Group F', value: 0 },
  { name: 'Group G', value: 0 },
  { name: 'Group H', value: 0 },
], ];

//main function start
const TeacherReviewQuestion :React.FC = () =>{
  //the type of Question and Answers
    const initialQuesAns = {
        lesson_num : -1,
        question_num: -1,
        questions: '',
        options : [''],
        summary:[-1],
    }

  //userHistoryQuesAns will an array that store all the answered questions and it's statstic
    const [userHistoryQuesAns, setUserHistoryQuesAns] = useState([initialQuesAns]);
  //piechartdataset will store the statstic for each questions' options
    const [piechardataset, setPieChartDataSet] = useState(piechartdata)
  //char num is used to determine which chart which dataset will be displayed in the piechart
    const [charnum, setCharnum] = useState(0)
  
  //handleSelectQuesAns: 
  //1.give some css effect that enable user know which question they selected
  //2.display the corresponding options and the piechart to the user
  //3.remove the css effect and corresponding statisc when click the same question again
    const handleSelectQuesAns = (QuesAnsID:string, i:number) => {
      //get to know which question was selected
        const QuesAnsItem = document.getElementById(QuesAnsID)
      //if found
        if(QuesAnsItem){
      //check whether it has been selected already?
          if(QuesAnsItem.classList.contains("TrQuesAnsActive")){
          //if yes, remove the selected css effect
            QuesAnsItem.classList.remove('TrQuesAnsActive')
          //call the function to remove all the info
            handleSelectQuesAnsOption(-1);
            return;
          }
        }
      //if it is not a selected question, get all the question first
        const QuesAns = document.querySelectorAll('.TeaQuesAns')
        if(QuesAns){
          //then clear the selected css effect for all the questions
          QuesAns.forEach(l=> l.classList.remove('TrQuesAnsActive'))
        }
    
        if(QuesAnsItem){
          //add the selected css effect to this question
          QuesAnsItem.classList.add('TrQuesAnsActive')
        }
    
        //display its options and piechart
        handleSelectQuesAnsOption(i)
      }
    
    
    //handleSelectQuesAnsOption:Display the corresponding options and piechart for the question i
    const handleSelectQuesAnsOption = ( i:number) => {
        let htmlStr = '';
        //get the option section
        const Treview_ansitem = document.getElementById("Treview-ansitem")
        if(i < 0){
        //if i < 0, that means remove all the info
          clearQuesAnsOption();
        //set the char num = 0
          setCharnum(0)
        //end function
          return;
        }

        //if i > 0
        if(Treview_ansitem){
          //get the option section inside the ans section
          const Treview_ansitemopt = document.getElementById("Treview-ansitemopt")
          if(Treview_ansitemopt){
          //index is used for assign different colour to the piechart
            let index = 0;
            userHistoryQuesAns[i].options.forEach(function (item) {
                htmlStr = htmlStr + `
                  <div style = background-color:${PIECHARTCOLORS[index % PIECHARTCOLORS.length]};>${item}</div>
                `;
                ++index;
            });
          //change the inner html
            Treview_ansitemopt.innerHTML = htmlStr;
          }
          //set the char num to i + 1 
          setCharnum(i+1)
        }
    }


    //clear all the info 
    const clearQuesAnsOption = ()=> {
        const Treview_ansitemopt = document.getElementById("Treview-ansitemopt");
        if(Treview_ansitemopt){
          //empty html
            Treview_ansitemopt.innerHTML = '';
        }
    }

    //use effect start
    useEffect(() => {
        let len = 0;
         GetQuestionReview().then(
          (data) =>{
            if(data){
              userHistoryQuesAns.pop();
              len = data.question.length;
              for(let i = 0; i < len; ++i){
                let tempques:string = data.question[i].statement;
                let anslen = data.option[i].length;
                let tempopt:string[] = [];
                let tempstaopt:number[]=[];
                let templessonnum:number = data.question[i].lesson_num
                let tempquestionnum:number = data.question[i].question_num
                for(let j = 0; j < anslen; ++j){
                  tempopt[j] = data.option[i][j].statement;
                  tempstaopt[j] = data.summary[i][j];
                }
                userHistoryQuesAns.push({
                    lesson_num : templessonnum,
                    question_num : tempquestionnum,
                    questions : tempques,
                    options: tempopt,
                    summary:tempstaopt
                });
              }
            }
            const questionitem = document.getElementById("Treview-questionitem")
            if(questionitem){
                let htmlstr = '';
                for(let n = 0; n < len; ++n){
                    htmlstr += `
                    <div class = "TeaQuesAns" id = TeaQuesAns${n}>
                        <span>Lesson: ${userHistoryQuesAns[n].lesson_num} </span> 
                        <span> Question: ${userHistoryQuesAns[n].question_num}</span>
                        <p>${userHistoryQuesAns[n].questions}</p>
                    </div>
                    `
                }
                questionitem.innerHTML = htmlstr;
                for(let k = 0; k < len ; ++k){
                    const ansStatistic = document.getElementById("TeaQuesAns" + k)
                    if(ansStatistic){
                        ansStatistic.addEventListener("click",()=>handleSelectQuesAns("TeaQuesAns" + k,k))
                    }
                }
            }
            //store different summary for different questions
            for(let k = 1; k < len+1; ++k){
              let temp = [{name:'',value : -1}]
              temp.pop();
              for(let c = 0; c < userHistoryQuesAns[k-1].options.length; ++c){
                temp.push({name:userHistoryQuesAns[k-1].options[c],value:userHistoryQuesAns[k-1].summary[c]});
              }
              piechartdata.push(temp);
            }
            setPieChartDataSet(piechartdata)
          }
        ).catch((error) => {
          userHistoryQuesAns.pop()
        });
      }, []);


    const headerBtn = ()=> {
        secureLocalStorage.setItem("page",'teacherhome');
        if(token === null){
          LogOut();
        }
        window.location.reload();
      }
  
    return (  
        <div className="Treview-body">
            <div className="Treview-header">
                <div className="headerBtn"> <a href="#" onClick={() => headerBtn()}><i className='bi bi-arrow-return-left TRicon'></i></a></div>
            </div>
            <div className="Treview-animation">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className="Treview-question">
                <div className="Treview-questionHeader">
                    <h2>Questions</h2>
                </div>
                <div className="Treview-questionitem" id = "Treview-questionitem">

                </div>
            </div>
            <div className="Treview-result">
                <div className="Treview-ansHeader">
                    <h2>Statistic</h2>
                </div>
                <div className="Treview-ansitem" id = "Treview-ansitem">
                    <div className="Treview-ansitemopt" id ="Treview-ansitemopt" >

                    </div>
                    <div className="Treview-ansitemchart" id = "Treview-ansitemchart">
                    
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                          <Pie
                            data={piechardataset[charnum]}
                            cx="50%"
                            cy="50%"
                            label
                            outerRadius={100}
                            fill="#8884d8"
                            dataKey="value"
                            isAnimationActive={false}
                          >
                          {piechardataset[charnum].map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={PIECHARTCOLORS[index % PIECHARTCOLORS.length]} />
                          ))}
                          </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default TeacherReviewQuestion;