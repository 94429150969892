//calls axios
import axios from "axios";

//crpy the localstorage data
import  secureLocalStorage  from  "react-secure-storage";

//API url
export const API_URL = `${process.env.REACT_APP_BACKEND_SERVER_URL}`;

//call axios
//POST {username, password} & save JWT to Local Storage
export const login = (userid: string, password: string) => {
  return axios
      .post(API_URL + '/users/login', { 
        userid,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          secureLocalStorage.setItem("accessToken", response.data.accessToken);
          secureLocalStorage.setItem("userid",response.data.user.userid);
          secureLocalStorage.setItem("role",response.data.user.role);          
          secureLocalStorage.setItem("school_class", response.data.school.concat(response.data.class));          
          secureLocalStorage.setItem("class_num",response.data.class_num);
        }
        return response.data;
      });
  };

export const LogOut = () =>{  
  secureLocalStorage.clear();
  window.location.href= "/";
  window.location.reload();
}