import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from './auth-service';

//socket url
export const SOCKET_URL = API_URL

//GET {accessToken} : get all the student data below to that teacher 
//get stored user information (including JWT

//teacher table

//get stored teacher information
export const getTeacherdata = () => {
  return axios
    .get(API_URL + '/teachers/info',{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

export const loadstudentdata= () => {
  return axios
    .get(API_URL + '/teachers/all-students-properties',{  
      headers: authHeader()
    })
    .catch(error => {
      return error
    })
};

//gen random role
export const randomrole = () => {
  return axios
    .post(API_URL + '/teachers/randomrole',{},{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//count null role
export const checknull = async () => {
  return await axios
    .get(API_URL + '/teachers/nullexist',{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return error;
    });
};
export type studentlistitem = {
  class_num:number,
  pressure:number
}

//teacher update the student data by calling this api at editscore page
export const ChangeStudentData = async (iq:number, eq:number,asset:number, studentlist:studentlistitem[]) => {
  console.log({
    dIq:iq,
    dEq:eq,
    dAsset:asset,
    studentList:studentlist
  })
  return await axios
  .patch(API_URL + '/teachers/students-properties',
  {
    dIq:iq,
    dEq:eq,
    dAsset:asset,
    studentList:studentlist
  },
  {  
    headers: authHeader()
  }
  );
}

//count students of the class
export const studentcount = async () => {
  return await axios
    .get(API_URL + '/teachers/studentcount' , {      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//student table

//get stored student information
export const getstudentdata = () => {
  return axios
    .get(API_URL + '/students/info',{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//student get his/her properties
export const GetStudentProperties = () => {
  return axios
  .get(API_URL + '/students/properties',{
    headers:authHeader()
  })
  .catch(error => {
    return error
  }) 
}

// student see doctor
export const SeeDoctor = async () => {
  return await axios
    .patch(API_URL + '/students/see-doctor', {}, {
      headers: authHeader()
    })
    .then(response => {
      window.location.reload()
    })
    .catch(error => {
      console.log(error)
    })
}

//question table

//get question of specified lesson
export const getQuestion = async (lesson_num:number) => {
  return await axios
    .get(API_URL + '/questions/list-all/' + lesson_num,{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//get all questions
export const getAllQuestion = async () => {
  return await axios
    .get(API_URL + '/questions/list-all',{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//answer table

//get option
export const getOption = async (lesson_num:number, question_num:number) => {
  return await axios
    .get(API_URL + '/answers/list/' + lesson_num + '/'+ question_num,{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//save answers to db for student changes
export const saveanswer = async (lesson_num:number, question_num:number, answers:any[]) => {
  return await axios
    .patch(API_URL + '/answers/students/' + lesson_num + '/'+ question_num, answers, {      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return error;
    });
};

//student answer table
export const GetQuestionReview = async () => {
  return await axios
  .get(API_URL + '/studentsanswers/list', {
    headers: authHeader()
  })
  .then(response =>{
    return response.data;
  })
  .catch(error => {
    return error;
  });
};

//save answers to db for record
export const recordanswer = async (lesson_num:number, question_num:number, answers:any[], summary:any[]) => {
  return await axios
    .patch(API_URL + '/studentsanswers/update/' + lesson_num + '/'+ question_num, {answers: answers, summary: summary}, {      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

//check number of questions answered
export const answeredcount = () => {
  return axios
    .get(API_URL + '/studentsanswers/answered', {      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return error;
    });
};

//get questionMap
export const questionMap = () => {
  return axios
    .get(API_URL + '/studentsanswers/questionmap', {      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};

export const getQuota = async (lesson_num:number, question_num:number) => {
  return await axios
    .get(API_URL + '/answers/list/' + lesson_num + '/'+ question_num,{      
      headers: authHeader()
    })
    .then(response => {
      return response.data;      
    })
    .catch(error => {
      return null;
    });
};