//axios calls
import {LogOut} from "../services/auth-service";
import {loadstudentdata, ChangeStudentData, studentlistitem} from "../services/user-service";
//React
import React, { useState, useEffect} from "react";
//css
import './Editscores.css';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//secure the localstorage
import  secureLocalStorage  from  "react-secure-storage";

//declare the length of student list
let len = 0;

//get the token
const token = secureLocalStorage.getItem("accessToken");

//main function start
const Editscores: React.FC = () => {

//define the data interface each student data
  interface data{
    classnum:number;
    iq:number;
    eq:number;
    asset:number;
    pressure:number;
  }

//UseState define
//IQ Value
const [iqValue, setIqValue] = useState(0)

//EQ Value
const [eqValue, setEqValue] = useState(0)

//Asset Value
const [assetValue, setMoneyValue] = useState(0)

//declare an empty student list with studentlistitem type in the user-service
//will be used for calling the changestudent data API
let studentlist:studentlistitem[] = [];

//return button function
const EDreturnbtn = ()=> {
  secureLocalStorage.setItem("page",'teacherhome');
  if(token === null){
    LogOut();
  }
  window.location.reload();
}

//Display the updated IQ value to the user
const getIQvalue = () => {
  return iqValue;
}

//Display the updated EQ value to the user
const getEQvalue = () => {
  return eqValue;
}

//Handle the asset value input by the user
const assetsinputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredAsset = parseInt(event.target.value) || 0;
    setMoneyValue(enteredAsset);
};

//QuickSort function that make the datalist display in ascending order according to the student number
const QuickSort = (datalist:data[], len:number, start:number,end:number) => {
  //base case
  if( start >= end){
    return;
  }
  let stdnum = datalist[start].classnum;
  let left = start, right = end;
  while(left !== right ){
    while(datalist[right].classnum > stdnum && right !== left){
      right--;
    }
    while(datalist[left].classnum <= stdnum && right !==left){
      left++;
    }
    //swap
    let temp:data = datalist[left];
    datalist[left] = datalist[right];
    datalist[right] = temp;
  }
  //while loop end, switch the reference number to the position
  let temp:data = datalist[start];
  datalist[start] = datalist[left]
  datalist[left] = temp;
  QuickSort(datalist,len,start,left-1);
  QuickSort(datalist,len,left+1,len-1);
}

//data list will store all the data from backend
let datalist:data[] = [];

//useEffect start
useEffect(() => {
  //set the datahtml to empty
  let datahtml:string ='';
  //call the get-all-student-porperties API
  loadstudentdata().then(
    (response) =>{
      //if API return something
      if(response)
      {
        //!!!!!temp method to deal with JWT expired force LogOut!!!!!!
        if(response.message === 'Request failed with status code 500'){
          LogOut();
        }
        //record the length
        len = response.data.length;
        //for loop pushing data into the datalist
        //why not for each? as response's datatype not confirm, typescript not allow
        for(let i = 0; i < len; ++i){
          datalist.push({
            classnum:response.data[i].class_num,
            iq:response.data[i].iq,
            eq:response.data[i].eq,
            asset:response.data[i].asset,
            pressure:response.data[i].pressure
          })
        }
        //Sorting the datalist
        QuickSort(datalist,len,0,len-1);
        //for adding the html id
        let dataid = 0;
        //transform the sorted datalist into the html format
        datalist.forEach(function(data){           
          datahtml = datahtml + ` 
          <div class = EDcontent-large1data id = ${dataid}>
            <div>${data.classnum}</div>
            <div>${data.iq}</div>
            <div>${data.eq}</div>
            <div>${data.asset}</div>
            <div>${data.pressure}</div>
          </div>
          `
          ++dataid;
        })

        //display the sorted datalist
        const datacontent = document.getElementById("datacontent");
        if(datacontent){
          datacontent.innerHTML = datahtml;
        }

        //add click eventlister to every data, so that they can havne selected effect
        const data = document.querySelectorAll('.EDcontent-large1data')
        if(data){
          data.forEach(l => l.addEventListener('click',function(){l.classList.toggle('EDselected')}))
        }
      }
    }
  );
}, [])

//handle the apply btn
const handleApply = () => {
  //mostly as same as in the useEffect
  loadstudentdata().then(
    (response) =>{
      if(response)
      {
        len = response.data.length;
        for(let i = 0; i < len; ++i){
          datalist.push({
            classnum:response.data[i].class_num,
            iq:response.data[i].iq,
            eq:response.data[i].eq,
            asset:response.data[i].asset,
            pressure:response.data[i].pressure
          })
        }
        QuickSort(datalist,len,0,len-1);
        //Here, we get the seleted data by looking wheter they have EDselected style or not
        const data = document.querySelectorAll('.EDselected')
        if(data){
          //for each data with EDselected style, we store its classnumber and pressure properties into the studentlist
          data.forEach(l => studentlist.push({
            class_num:datalist[Number(l.id)].classnum,
            pressure:datalist[Number(l.id)].pressure,
          }))
        }
        //call the API to update student's properties in the studentlist with the IQ, EQ, Asset values input by user 
        ChangeStudentData( iqValue, eqValue, assetValue, studentlist).then(
          ()=>{
            //force reload to re-render the whole page to display the updated value
            window.location.reload();
          }
        )
      }
    }
  );
}
  
  return (
    //html elements
      <div className="Edbody">
        <div className="EDcontainer">
          <div className="EDheader">
            <div className="EDheaderbtn"> <a href="#" onClick={() => EDreturnbtn()}><i className='bi bi-arrow-return-left EDicon'></i></a></div>
          </div>
          <div className="EDcontent-large1">
            <div className="EDcontent-large1header">
              <div>userid</div>
              <div>IQ</div>
              <div>EQ</div>
              <div>Asset</div>
              <div>stress</div>
            </div>
            <div className="EDcontent-large1datacontent" id = "datacontent">

            </div>
          </div>
          <div className="EDcontent-large2">
            <div className="EDeditbtn">
              <div className="EDbtnname">IQ</div>
              <button  onClick={() => setIqValue(iqValue-1)}>−</button>
              <input  value = {iqValue} onChange={getIQvalue}/>
              <button  onClick={() => setIqValue(iqValue+1)}>+</button>
            </div>
            <div className="EDeditbtn">
              <div className="EDbtnname">EQ</div>
              <button  onClick={() => setEqValue(eqValue-1)}>−</button>
              <input  value = {eqValue} onChange={getEQvalue} />
              <button  onClick={() => setEqValue(eqValue+1)}>+</button>
            </div>
            <div className="EDeditbtn">
              <div className="EDbtnname">Asset</div>
              <div></div>
              <input type="number" onChange={assetsinputHandler} />
              <div></div>
            </div>
            <div className="applybtn">
              <button onClick={() =>  handleApply()}><span>Apply</span></button>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Editscores;