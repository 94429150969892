import React, { useEffect, useState } from 'react';
import {LogOut} from "../services/auth-service";

import Answer from './Answer';

//css
import "./Loading.css"
import './PlayerScreen.css';

//socketio
import io from 'socket.io-client'
import { SOCKET_URL } from '../services/user-service';

//crypt to localstorage
import  secureLocalStorage  from  "react-secure-storage";

//img
import police_car from '../assets/submitwaiting/police_car.gif' // relative path to image 

//read the localstorage to get needed info
const token = secureLocalStorage.getItem("accessToken");
const userid:string|undefined = secureLocalStorage.getItem("userid")?.toString();
const roomname = secureLocalStorage.getItem("school_class")
const class_num = secureLocalStorage.getItem("class_num")


//main function start
const PlayerScreen :React.FC = () =>{
  
  //useState declare
  //value that determine question page or result page to show
  const [isQuestionScreen , setisQuestionScreen ] = useState(true)
  const [isResultScreen , setisResultScreen ] = useState(false)

  //fake question after student get in the question page
  const [question, setQuestion] = useState('RULES')
  //here is the content of the options that will show on the screen
  const [answerContent, setAnswerContent] = useState(["LISTEN", "留心", "望出面", "ENJOY"])
  //copy of the options that before being random
  const [optionscopy, setoptionscopy] = useState([])
  //the result properties of after students answered the question
  const [result, setresult] = useState({iq:0, eq:0, asset:0, pressure:0})
  //copy of dValue??
  const [dValuescopy, setdValuescopy] = useState([])
  //the effect that this option bring to student
  const [ansdValue, setansdValues] = useState({
    dIq:0,
    dEq:0,
    dAsset:0,
    dPressure:0
  })
  let answerindex:number[] = []

  //useEffect Start
  useEffect(() => {   
    //page switch
    const isQuestionScreen = JSON.parse(secureLocalStorage.getItem("QuestionScreen")?.toString()||'null')    
    if(isQuestionScreen!=null) setisQuestionScreen(isQuestionScreen)
    //player should see the question page first
    else secureLocalStorage.setItem("QuestionScreen", JSON.stringify(true))

    const isResultScreen = JSON.parse(secureLocalStorage.getItem("ResultScreen")?.toString()||'null')    
    if(isResultScreen!=null) setisResultScreen(isResultScreen)
    //player should see the result page after teacher press the end question button
    else secureLocalStorage.setItem("ResultScreen", JSON.stringify(false))    
  },[])
  
  //socket.io
  let socket:any = null    
  const [currentSocket, setCurrentSocket] = useState(socket)
  useEffect(() => {
    //function for connect to the socket
    async function connect () {
      socket = io(SOCKET_URL);   
      setCurrentSocket(socket)
    }
    //function excute
    connect();
    //send the connect msg to notice the host student is in
    socket.on('connect', () => {        
      socket.emit("connected", 'hi')
      socket.emit('join-room', roomname, 'student')                  
      socket.emit('student-joined', roomname, userid)
    });

    //notify teacher on teacher's connection
    socket.on('teacher-connected', (socketid:any) => {
      socket.emit("student-joined", roomname, userid, socketid)
    });    

    //force logout
    socket.on('duplicated-connection', () => {
      console.log('duplicated-connection')
      LogOut()
    })
    
    //teacher post new question
    socket.on('new-question-by-teacher', (response:any) => {
      console.log(answerContent)
      setoptionscopy(response.options)
      setdValuescopy(response.ansdValues)
      //default first choice
      setansdValues(response.ansdValues[0])

      secureLocalStorage.setItem("options", JSON.stringify(response.options))
      secureLocalStorage.setItem("dValuescopy", JSON.stringify(response.ansdValues))
      secureLocalStorage.setItem("ansdValue", JSON.stringify(response.ansdValues[0]))

      //generate random number
      const min = 0;
      const max = response.options.length;
      const rand = Math.floor(min + Math.random() * (max - min));

      // display shuffled option
      // content: array of shuffled option
      let content:any[]=[]
      let arraylength = response.options.length
      response.options.forEach((value:any, i:any) => {
        answerindex.push((rand+i)%arraylength)
        content[i] = response.options[(rand+i)%arraylength]        
      })
      //set both useState and local storage
      setQuestion(response.question)
      setAnswerContent(content)
      secureLocalStorage.setItem("question", response.question)
      secureLocalStorage.setItem("answerContent", JSON.stringify(content))

      //content switch
      setisQuestionScreen(true)
      secureLocalStorage.setItem("QuestionScreen", JSON.stringify(true))

      setisResultScreen(false)
      secureLocalStorage.setItem("ResultScreen", JSON.stringify(false))
    })

    //teacher end question
    socket.on('end-question-by-teacher', (response:any[]) => {
      // response: result gameinfo of all students of the class from teacher
      answerindex=[]
      console.log(response)
      if(response.length!==0) {      
        response.some((x:any) => {
          if (x.class_num === class_num){
            setresult({iq: x.iq, eq: x.eq, asset: x.asset, pressure: x.pressure})
            secureLocalStorage.setItem("result", JSON.stringify({iq: x.iq, eq: x.eq, asset: x.asset, pressure: x.pressure}))
          }
          return x.class_num === class_num
        })
      }
      //content switch
      setisQuestionScreen(false)
      secureLocalStorage.setItem("QuestionScreen", JSON.stringify(false))
      setisResultScreen(true)
      secureLocalStorage.setItem("ResultScreen", JSON.stringify(true))   
    })

    socket.on('quota is full', (index:number) => {    
      const localStorageContent = JSON.parse(secureLocalStorage.getItem("answerContent")?.toString()||'[]')
      const dValuescopy = JSON.parse(secureLocalStorage.getItem("dValuescopy")?.toString()||'[{}]')
      let deletedindex = localStorageContent.length+1;
      let defaultindex = 0;

      let deletedContent = localStorageContent
      for(let i=0;i<answerindex.length;i++){
        if(answerindex[i] === index){
          delete deletedContent[i];
        }
      }
      deletedContent.forEach((value:any, i:any) => 
        {if(value !== null && i < deletedindex){
          deletedindex = i;
          defaultindex = answerindex[i]
        }}
      );
      setansdValues(dValuescopy[defaultindex])
      setAnswerContent(deletedContent)
      secureLocalStorage.setItem("answerContent", JSON.stringify(deletedContent))
      secureLocalStorage.setItem("ansdValue", JSON.stringify(dValuescopy[defaultindex]))
    })
  }, [socket])

  useEffect(() => {
    //init local storage to useStates
    const question = secureLocalStorage.getItem("question")?.toString()
    if(question) setQuestion(question)
    else secureLocalStorage.setItem("question", 'RULES')

    // ...toSting()||'null value of the datatype of the variable'
    // '[]' for array
    // '[{}]' for array of objects
    // '{}' for object    
    const answerContent=JSON.parse(secureLocalStorage.getItem("answerContent")?.toString()||'[]')
    console.log(answerContent)
    if(answerContent.length!==0) setAnswerContent(answerContent)
    else secureLocalStorage.setItem("answerContent", JSON.stringify(["LISTEN", "留心", "望出面", "ENJOY"]))

    const optionscopy = JSON.parse(secureLocalStorage.getItem("optionscopy")?.toString()||'[]')
    if(optionscopy.length!==0) setoptionscopy(optionscopy)    

    const dValuescopy = JSON.parse(secureLocalStorage.getItem("dValuescopy")?.toString()||'[{}]')
    if(dValuescopy.length!==0) setdValuescopy(dValuescopy)

    console.log(secureLocalStorage.getItem("ansdValue"))
    const ansdValue = JSON.parse(secureLocalStorage.getItem("ansdValue")?.toString()||'{}')
    if(!secureLocalStorage.getItem("ansdValue")) setansdValues(ansdValue)
    
    const Effect = document.getElementById('stdshowResText1')
    if(Effect) {
      if (ansdValue.dIq) { //having value for dIq = having values for all dIq dEq dAsset dPressure
        Effect!.innerHTML=
        `<h1>Effect</h1>
        <span> IQ ${ansdValue.dIq}</span>
        <span> EQ ${ansdValue.dEq}</span>
        <span> Asset ${ansdValue.dAsset}</span>
        <span> Pressure ${ansdValue.dPressure}</span>`
      } else { //otherwise it is a state question
        Effect!.innerHTML=`<span>No Effect</span>`
      }
    }
    
    console.log(secureLocalStorage.getItem("result"))
    const result = JSON.parse(secureLocalStorage.getItem("result")?.toString()||'{}')
    if(!secureLocalStorage.getItem("result")) setresult(result)

    const Result = document.getElementById('stdshowResText2')
    if(Result) {
      Result!.innerHTML=
      `<h1>Result</h1>
      <span>IQ ${result.iq} </span>
      <span>EQ ${result.eq} </span> 
      <span>Asset ${result.asset} </span>
      <span>Pressure ${result.pressure} </span>`
    }
  }, [isQuestionScreen, isResultScreen])

  // when student clicked an answer
  const checkAnswer = (answer: any) => {
    console.log(answer)
    //content switch
    setisQuestionScreen(false)
    secureLocalStorage.setItem("QuestionScreen", JSON.stringify(false))
    //find originally option order
    optionscopy.some((value, i) => {
      if(answer === optionscopy[i]){  
        console.log(dValuescopy[i])  
        setansdValues(dValuescopy[i])
        secureLocalStorage.setItem("ansdValue", JSON.stringify(dValuescopy[i]))
        console.log(class_num)
        //send the index and class number to the teacher
        currentSocket.emit('answer', roomname, {stuClassNum: class_num, answer:i})        
      }
      return answer === optionscopy[i]
    })
  }

  const returnBtn = () => {
    secureLocalStorage.removeItem("teacherstart")
    secureLocalStorage.removeItem("question")
    secureLocalStorage.removeItem("answerContent")
    secureLocalStorage.removeItem("optionscopy")
    secureLocalStorage.removeItem("dValuescopy")
    secureLocalStorage.removeItem("ansdValue")
    secureLocalStorage.removeItem("result")
    secureLocalStorage.removeItem("QuestionScreen")
    secureLocalStorage.removeItem("ResultScreen")
    if(token === null){
      LogOut();
    }
    window.location.reload();
  };

   //handler of waiting page after submit
   const handleSun = () => {
    const sun = document.getElementById("sun")
    const scene = document.getElementById("scene")
    if(sun){

    }
    if(scene){
      scene.classList.toggle("stdsubmitwaitsceneday")
      scene.classList.toggle("stdsubmitwaitscenenight")
    }
  }
  
  return (
      <> 
        <div className={"page"}>
          {isQuestionScreen ? (
            <>
            <div className={"question-preview"}>
            <h1>{question}</h1>
            </div>
            <div className="answers-container">
                {answerContent.map((content,i) => (
                  (content && 
                    <Answer
                    key = {i}
                    onClick={() => checkAnswer(content)}
                    content={content}
                    />)
                ))}
            </div>
            </>
          ) : (isResultScreen ?  (    
          <> {/* result screen */}
          <div className='PSreturnbtn '>
          <a href = '#' onClick={returnBtn}><i className="bi bi-arrow-left-square-fill icon"></i></a>
          </div>
          <div className="stdShowRes">
            <div>
              <div className='stdshowResStars01'></div>
              <div className='stdshowResStars02'></div>
              <div className='stdshowResStars03'></div>
              <div className='stdshowResStars04'></div>
            </div>
            <div className= 'stdshowResText1' id='stdshowResText1'>
            </div>
            <div className= 'stdshowResText2' id='stdshowResText2'>
            </div>
          </div>
          </>   
            ): (
            <div className='stdsubmitwaitsceneday' id = 'scene'> {/* waiting screen */}   
              <div className='stdsubmitwaitscenensun' id='sun' onClick={handleSun}></div>
              <div className='stdsubmitwaitscenebg'>
                <img src={police_car} className='stdsubmitwaitscenecar1' alt="car"/>
                <img src={police_car} className='stdsubmitwaitscenecar2' alt="car"/>
              </div>
              <h1>Submitted! Waiting on other players</h1>
            </div>
            ))}
      </div>
    </>
  )
}

export default PlayerScreen;