//axios calls
import {LogOut} from "../services/auth-service";
import {questionMap, getQuestion, getOption, getAllQuestion, saveanswer, studentcount, recordanswer, answeredcount, getQuota} from "../services/user-service";

//React
import React, { useState, useEffect } from "react";
//css
import './Questions.css';

//socketio
import {io} from 'socket.io-client'
import { SOCKET_URL } from '../services/user-service';

//navigate
import { NavigateFunction, useNavigate } from 'react-router-dom';

//crypt to localstorage
import  secureLocalStorage  from  "react-secure-storage";

const token = secureLocalStorage.getItem("accessToken");
const roomname = secureLocalStorage.getItem("school_class")

type ansdValue = {
  iq:number,
  eq:number,
  asset:number,
  pressure:number
}
let ansdValues:ansdValue[] = []

let quota:number[]=[]
//statement: statement of the clicked question
//option: options of the clicked question
let statement='', option:string[]=[]
/**
 * allanswer: all answer
 *            for frontend, tracking students' answer, in answering sequence
 *            update when receiveing new response from students and when question is ended
 *            as answerrecord for api recordanswer
 *            for generating summary (allanswercount)
 */
/**
 * allresponse: all repsonse
 *              response from students
 *              format: {stuClassNum: , answer: }
 */
//allanswercount: all answer count, summary of students' answers
let allanswer:number[] = [], allresponse:any[]=[], allanswercount:number[]=[0,0,0,0]
//notanswered: stored all -1 initially, update to students' answer when receiving their response
//notansweredcount: for generating frontend summary
let notanswered:number[]=[], notansweredcount:number = 0
//joinedArr: joined array (without duplication)
//newstudentlist: copy of joinedArr for temp use when new student joined
//                use to check for duplication and execute force logout
let joinedArr=[{socketid: '', userid: ''}], newstudentlist=[{socketid: '', userid: ''}]

function checkIfDuplicateExists(w:any){
  return new Set(w).size !== w.length 
}

const Question :React.FC = () =>{
  
  useEffect(() => {
    answeredcount().then((answeredcount) => {
      //!!!!!temp method to deal with JWT expired force LogOut!!!!!!
      if(answeredcount.message === 'Request failed with status code 500'){
        LogOut();
      }
      getAllQuestion().then((questionList) => {
        console.log(questionList)
        for (let i = answeredcount; i < questionList.length; i++) {  
          if (!document.getElementById(`${questionList[i].lesson_num} ${questionList[i].question_num}`))               
              displayQuestion(i, questionList)
        }
      })
    })
  }, []);

  //navi function
  let navigate: NavigateFunction = useNavigate();
  const emptynumberarray:number[]=[]
  const emptystringarray:string[]=[]
  const emptyansdValues:ansdValue[]=[]

  //socket connection
  let socket:any = null
  const [currentSocket, setCurrentSocket] = useState(socket)

  useEffect(() => {
    async function connect () {
        socket = io(SOCKET_URL);
        setCurrentSocket(socket)
        // console.log(socket)
    }
    connect();
    socket.on('connect', () => {
      socket.emit("connected", 'hi')
      socket.emit('join-room', roomname, 'teacher')
      socket.emit('teacher-join', roomname)
    });

    socket.on('new-student-connect', (user:string, newsocketid:string) => {
      console.log(user, 'joined')         
      console.log(joinedArr)
      const newstudent={socketid: newsocketid, userid: user}        
      newstudentlist=[...joinedArr, newstudent]   
      joinedArr.some(x => {
        if (user === x.userid) {
          //force logout
          socket.emit('host-disconnect', x.socketid)
        }
        return user === x.userid
      })
      if (!checkIfDuplicateExists(newstudentlist)) {
        joinedArr.push(newstudent)
      }
    });

    socket.on('new-student-disconnect', (newsocketid:string) => { 
      joinedArr.forEach(x => {
        if (x.socketid===newsocketid) {
          console.log(x.userid,'left');
          x.socketid=''; 
          x.userid=''
        }
      })
    });

    //receive student answer
    socket.on('student-answer', (response:any) => {
      console.log(response)
      //save student answer
      allanswer.push(response.answer)
      allresponse.push((response))

      //empty array
      allanswercount.length=0
      //count total for each choice
      for (let i=0; i<option.length; i++) {
        allanswercount.push(allanswer.filter(x => x===i).length)
      }
      console.log(allanswercount)  

      //remove students with answer        
      notanswered[response.stuClassNum-1]=response.answer
      console.log(notanswered)    
      //check is the quota full
      for (let i = 0; i < allanswercount.length; i++) {
        if(allanswercount[i] === quota[i]){
          console.log('quota is full')
          //socket.emit('testing', student.school+student.class)
          socket.emit('quota', roomname, i)
        }
      }
    });
      
  }, [socket]);

  //choose and fetch questions
  const [lesson_num, setLesson_num] = useState(2)
  const [question_num, setQuestion_num] = useState(1)
  
  const [confirmavailable, setConfirmAvailable] = useState(true)
  const [resultavailable, setresultavailable] = useState(true)
  
  useEffect(() => {
    console.log('lesson', lesson_num, 'quesiton', question_num)
    statement=''
    quota=emptynumberarray
    option=emptystringarray
    ansdValues=emptyansdValues
    getQuestion(lesson_num).then((question) => {
      console.log(question)
      question.some((x:any) => {
        if (x.question_num === question_num) {
          statement=x.statement
        }
        return x.question_num === question_num
      })
    })
    if(lesson_num!==0 && question_num!==0) {
      getOption(lesson_num, question_num).then((options) => {
        for (const x of options) {
          ansdValues.push(x.dValues)
          option.push(x.statement)
        }
        console.log(ansdValues)
        console.log(option)
      })
    }
    getQuota(lesson_num, question_num).then( 
      (quotas) => {
        for (const x of quotas) {
          quota.push(x.quota)
        }
        console.log(quota)
    })
    setConfirmAvailable(true)     
  }, [lesson_num, question_num])

  function setquestion(lesson_num:number, question_num:number) {
    setLesson_num(lesson_num)
    setQuestion_num(question_num)
  }

  function displayQuestion(i:number, questionList:any) {
    //create each question in <div> with classname 'question' in <a> 
    
    const question = `Lesson ${questionList[i].lesson_num} Question ${questionList[i].question_num} ${questionList[i].statement} `
    const box = document.getElementById('postquestion') as HTMLInputElement | null; 

    const div = document.createElement("div")
    div.className = 'question'
    div.id = `${questionList[i].lesson_num} ${questionList[i].question_num}`      
    div.textContent = question

    const a = document.createElement('a')
    a.href='#'        
    a.onclick = function() {
      setquestion(questionList[i].lesson_num, questionList[i].question_num);
      // selected effect
      questionList.forEach((value:any, i:any) => {
        const div = document.getElementById(`${questionList[i].lesson_num} ${questionList[i].question_num}`)
        if(div?.classList.contains('Qselected')) div.classList.toggle('Qselected')
      })
      div.classList.toggle('Qselected')
    }

    if (box) {box.append(a); a.append(div)}
  }
  
  //teacher start session   
  const teacherstart = () => {
    currentSocket?.emit('authorised-start', roomname)
    console.log('start')
  }
  
  //confirm posting question   
  function post() { 
    console.log('post')
    questionMap().then((questionMap) => {
      answeredcount().then((answeredcount) => {
        // check if chosen q is next q to be answered
        if (questionMap[answeredcount].lesson_num === lesson_num && questionMap[answeredcount].question_num === question_num) {
          currentSocket.emit('new-question', roomname, {question: statement, options:option, ansdValues:ansdValues, quotas:quota})
          //reset states and var
          //empty the arrays
          allanswer.length=0
          allanswercount.length=0
          allresponse.length=0
          notanswered.length=0
          notansweredcount=0
          //find students without ans
          studentcount().then((count:number) => {
            for (var i=0; i<count; i++) {
              notanswered[i]=-1
            }      
          })
          console.log(notanswered)
          setresultavailable(false)
          setConfirmAvailable(false)
        } else {
          alert("not next question")
        }
      })
    })

  }

  //back to teachers home page
  function back() {
    console.log('back')
    if(token === null){
      LogOut();
    } else {
      secureLocalStorage.setItem("page",'teacherhome');
      navigate("/");
      window.location.reload();
    }
  }

  //end question
  function end() {
    console.log('end')
    questionMap().then((questionMap) => {
      answeredcount().then((answeredcount) => {
        // check if chosen q is next q to be answered
        if (questionMap[answeredcount].lesson_num === lesson_num && questionMap[answeredcount].question_num === question_num) {
          if(allresponse.length===0) allanswercount=[0,0,0,0]
      
          //set default ans (first choice) as first choice for students without ans
          notanswered.forEach((value, i) => {
            if(notanswered[i]===-1) {
              //check is the quota full
              console.log(allanswercount.length)
              for (let j = 0; j < allanswercount.length; j++) {
                console.log(allanswercount[j])
                if(allanswercount[j] !== quota[j]) {
                  allresponse.push({stuClassNum: i+1, answer: j})                  
                  notanswered[i]=j
                  allanswer.push(j)
                  allanswercount[j]++                  
                  break;
                }
              }
              notansweredcount++
            }
          })
          console.log(allresponse)
          console.log(notansweredcount)
      
          //count total for each choice
          allanswercount.length=0
          for (let i=0; i<option.length; i++) {
            allanswercount.push(allanswer.filter(x => x===i).length)
          }
          
          // api: save to db as record
          // answers array (notanswered)   
          // summary array (allanswercount)
          recordanswer(lesson_num, question_num, notanswered, allanswercount).then((res) => {      
            console.log(res)
            if(res!=null) {
              // record sucessful
              //api: update student game info
              saveanswer(lesson_num, question_num, allresponse).then((results) => {
                console.log(results)
                //send result to student
                currentSocket.emit('end-question', roomname, results)
              })
              // mark answered question
              const myNode = document.getElementById(`${lesson_num} ${question_num}`)    
              if (myNode !== undefined && myNode !== null) {
                myNode.innerHTML = "<div style='color:black'>answered <br> </div>"+ myNode.innerHTML;    
              }
            }
          })    
          //show result to teacher   
          setresultavailable(true)        
        } else {
          alert("Question record unsuccessful \nChoose the question you have posted just now to end the question")
        }
      })
    })
  }

  /**
   * confirmavailable: 
   *  true: question ended, confirm button available
   *  false: question broadcasting, new question not yet chosen
   * resultavailable:
   *  true: question ended, back button available, statistics of question available
   *  false: question broadcasting, end button available
   */  
  
  return (  
    <div className="qbody">            
        <div className="postquestion">                
        <a href='#' style={{backgroundColor: '#c64300'}} onClick={teacherstart}> <div className='question'>Start Session</div> </a>                
        Please do not reload after starting a question <br/> 
        Please do not reload before ending a question
        {resultavailable && <a href='#' style={{backgroundColor: '#c64300'}} onClick={back}> <div className='question'>return</div> </a>}
        {!resultavailable && <a href='#' style={{backgroundColor: '#c64300'}} onClick={end}> <div className='question'>end question</div> </a>}
        <div>  Default:: Lesson: 2 Question: 1</div> 
        {confirmavailable && (<a href='#' style={{backgroundColor: '#c64300', color:'black'}} onClick = {() => {post()} } > <div className='question' id='confirm' >confirm</div> </a>)}
        {!confirmavailable && resultavailable && option[0] && (`${option[0]}: ${allanswercount[0]} (Not answered: ${notansweredcount})`)}
        {!confirmavailable && resultavailable && option[0] && (<br/>)}
        {!confirmavailable && resultavailable && option[1] && (`${option[1]}: ${allanswercount[1]}`)}
        {!confirmavailable && resultavailable && option[1] && (<br/>)}
        {!confirmavailable && resultavailable && option[2] && (`${option[2]}: ${allanswercount[2]}`)}
        {!confirmavailable && resultavailable && option[2] && (<br/>)}
        {!confirmavailable && resultavailable && option[3] && (`${option[3]}: ${allanswercount[3]}`)}
        {!confirmavailable && resultavailable && option[3] && (<br/>)}
        {!confirmavailable && resultavailable && option[4] && (`${option[4]}: ${allanswercount[4]}`)}
        {!confirmavailable && resultavailable && option[4] && (<br/>)}
        {!confirmavailable && resultavailable && option[5] && (`${option[5]}: ${allanswercount[5]}`)}
        {!confirmavailable && resultavailable && option[5] && (<br/>)}
        {!confirmavailable && resultavailable && option[6] && (`${option[6]}: ${allanswercount[6]}`)}        
        </div> 
        <div className="questionlist" id='postquestion'> </div>         
    </div>        
  );

};

export default Question;