//axios calls
import {LogOut} from "../services/auth-service";
import {randomrole, checknull} from "../services/user-service";

//React
import React, { useState, useEffect } from "react";
//css
import './Questions.css';
import './teacher.css';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//navigate
import { NavigateFunction, useNavigate } from 'react-router-dom';

//socketio
import {io} from 'socket.io-client'
import { SOCKET_URL } from '../services/user-service';

//crypt to localstorage
import  secureLocalStorage  from  "react-secure-storage";

const userid:string|undefined = secureLocalStorage.getItem("userid")?.toString();
const roomname = secureLocalStorage.getItem("school_class")

//joinedArr: joined array (without duplication)
//newstudentlist: copy of joinedArr for temp use when new student joined
//                use to check for duplication and execute force logout
let joinedArr=[{socketid: '', userid: ''}], newstudentlist=[{socketid: '', userid: ''}]
//useridlist: not unique
//uniqueUseridlist: unique, for display
let useridlist:string[]=[], uniqueUseridlist:string[]=[]
function checkIfDuplicateExists(w:any){
  return new Set(w).size !== w.length 
}

const BoardTeacher: React.FC = () => {  
  useEffect(() => {
    //studentlist
    displayAll()
    //show random role btn
    checknull().then((nullexist) => {
      console.log(nullexist)
      //!!!!!temp method to deal with JWT expired force LogOut!!!!!!
      if(nullexist.message === "Request failed with status code 500"){
        LogOut();
      }
      if (!nullexist) {
        setRoleAvailable(false)
        secureLocalStorage.setItem("roleavailable", false)
      } else {
        setRoleAvailable(true)
        secureLocalStorage.setItem("roleavailable", true)
      }
    })

  }, []);

  //navi function
  let navigate: NavigateFunction = useNavigate();

  let socket:any = null
  const [currentSocket, setCurrentSocket] = useState(socket)
  useEffect(() => {
    async function connect () {
        socket = io(SOCKET_URL);
        setCurrentSocket(socket)
        // console.log(socket)
    }
    connect();
    socket.on('connect', () => {      
      socket.emit("connected", 'hi')           
      socket.emit('join-room', roomname, 'teacher')
      socket.emit('teacher-join', roomname)
    });   

    socket.on('new-student-connect', (user:string, newsocketid:string) => {
      console.log(user, 'joined')         
      console.log(joinedArr)
      const newstudent={socketid: newsocketid, userid: user}        
      newstudentlist=[...joinedArr, newstudent]   
      joinedArr.some(x => {
        if (user === x.userid) {
          //force logout
          socket.emit('host-disconnect', x.socketid)
        }
        return user === x.userid
      })
      if (!checkIfDuplicateExists(newstudentlist)) {
        joinedArr.push(newstudent)
        useridlist.push(user)
      }

      console.log(useridlist)
      displayAll()
    });

    socket.on('new-student-disconnect', (newsocketid:string) => { 
      console.log(useridlist)
      joinedArr.forEach(x => {
        if (x.socketid===newsocketid) {
          console.log(x.userid,'left');
          const index = useridlist.indexOf(x.userid);
          if (index > -1) { // only splice array when item is found
            useridlist.splice(index, 1); // 2nd parameter means remove one item only
            console.log(useridlist)
          } 
          x.socketid=''; 
          x.userid=''
        }
      })
      displayAll()
    });
      
  }, [socket]);  

  function displayAll() {
    //clear existing
    const myNode = document.getElementById("joinedstudent");
    while (myNode?.firstChild) {
      if (myNode?.lastChild)
      myNode?.removeChild(myNode?.lastChild);
    }    
    //show unique users
    uniqueUseridlist=Array.from(new Set(useridlist));
    console.log(uniqueUseridlist)
    uniqueUseridlist.forEach(x => {displayStudent(x)})
  }

  //teacher start session   
  const teacherstart = () => {
    currentSocket?.emit('authorised-start', roomname)
    console.log('start')
  }

  function displayStudent(user:string) {
    const box = document.getElementById('joinedstudent') as HTMLInputElement | null;
    const div = document.createElement("div")
    div.textContent = user
    div.id = user
    if (box) box.append(div)
  }
  
  //go to Questions.tsx
  function postquestion() {  
    console.log('post')  
    secureLocalStorage.setItem("page",'postquestion');
    navigate("/");
    window.location.reload();
  }

  //go to TeacherReviewQuestion.tsx
  const reviewquestion = ()=>{  
    console.log('review')  
    secureLocalStorage.setItem("page",'review');
    navigate("/");
    window.location.reload();
  }

  //handle add/deduct score btn
  //go to Editscores.tsx
  const editscores = () => {
    secureLocalStorage.setItem("page", "editscores");
    navigate("/");
    window.location.reload();
  }

  const [roleavailable, setRoleAvailable] = useState(false)

  //handle random role generate btn
  const randomRole = () => {
    //call the random role API
    randomrole().then(()=>{
      //tell the joined student-side they can get their data on card
      currentSocket?.emit('gen-role', roomname)
      checknull().then((nullexist) => {
        console.log(nullexist)
        if (!nullexist) {
          setRoleAvailable(false)
          secureLocalStorage.setItem("roleavailable", false)
        }
      })
    })
  }    
  /**
   * roleavailable:
   *  true: show btn random role
   *  false: hide btns (Start Session, Post question, Review Quesiton)
   */

  return (
  <div className="tcbody">
    <div className="tccontainer">
      <div className="tcheader">Welcome, {userid}</div>
      <div className="tccontent-large1">
        <div className="tcpostquestion">
            {roleavailable && ('Please press random role')}                
            {!roleavailable && <a href = '#' onClick={teacherstart}>Start Session</a>}                
            {!roleavailable && <a href = '#' onClick={postquestion}>Post Question</a>}
        </div>
      </div>

      <div className="tccontent-large2">
        <div className="tcscore">
            <a href = '#' onClick={editscores} >Add/Deduct Score</a>
            {roleavailable && <a href = '#' onClick={randomRole} >Random Role</a>}
            {!roleavailable && <a href = '#' onClick={reviewquestion}>Review Question</a>}
        </div>
      </div>
      <div className="tcsignout"> <a href = '#' onClick={LogOut}>Sign Out <i className='bi bi-door-open-fill'></i></a></div>
      <div className="tcfooter">
        <span><i><b>Student Joinned</b></i></span> 
        <div className="joinedstudent" id="joinedstudent"></div>
      </div>
    </div>
  </div>
  );

};

export default BoardTeacher;