//JWT HEADER
import  secureLocalStorage  from  "react-secure-storage";

export default function authHeader() {
    const accessToken = secureLocalStorage.getItem("accessToken");
    if (accessToken) {
      return { authorization: 'Bearer ' + accessToken }; 
    } else {
      return { authorization: '' }; 
    }
  }