import React from "react";
import { BrowserRouter, Routes, Route} from "react-router-dom";

//crpy the localstorage data
import  secureLocalStorage  from  "react-secure-storage";

//page
import Login from "./Auth/SignInPage";
import PlayerScreen from "./components/PlayerScreen";
// import SignUp from "./Auth/SignUpPage";
import BoardStudent from "./components/student";
import BoardTeacher from "./components/teacher";
import Questions from "./components/Questions";
import Editscores from "./components/Editscores";
import TeacherReviewQuestion from "./components/TeacherReviewQuestion";

const App: React.FC = () =>{

  const routecontrol = () => {
    if(token!== null){
      switch(role){
        case "student":{
          if(teacherstart === true){
            return <PlayerScreen/>
          }else{
            return <BoardStudent />
          }
        }
        case "teacher": {
          if (page === 'teacherhome')          
            return <BoardTeacher/>
          else if (page === 'postquestion')
            return <Questions/>
          else if(page === 'editscores')
            return <Editscores/>
          else if(page === 'review')
            return <TeacherReviewQuestion/>
          else {
            return <BoardTeacher/>
          }
        }
        default:
          secureLocalStorage.clear();
          return <Login />
          
      }
    }
    else{
      secureLocalStorage.clear();
      return <Login />
    }
  }

  //check JWT token
  //now we can protect from CSRF if we store it in the local storage
  //however  cannot protect from Cross-Site Scripting(XSS). 
  //Attackers can use JavaScript to manipulate data in the localStorage
  //crypto!!!
  const token = secureLocalStorage.getItem("accessToken");
  const teacherstart = secureLocalStorage.getItem("teacherstart");
  const page = secureLocalStorage.getItem("page");
  const role = secureLocalStorage.getItem("role")
  return (
    <BrowserRouter>
            <Routes>
              <Route path="/" element={routecontrol()} />
              <Route path="*" element={routecontrol()} />
            </Routes>
    </BrowserRouter>
  );
}

export default App;