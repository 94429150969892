//react use state
import React, {useState} from 'react';

//css
import './SignInPage.css';

//jump pages
import { NavigateFunction, useNavigate } from 'react-router-dom';

//local validation(prevent junk input)
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Formik, Field, Form, ErrorMessage } from "formik";

//axios calls
import {login} from "../services/auth-service";

const Login : React.FC = () =>  {
  /*---------------------------------------if already login => redirect inside route ------------------------------------------------------------------------*/
  let navigate: NavigateFunction = useNavigate();
  /*---------------------------------------local input validation ------------------------------------------------------------------------*/
  //Define a new datatype named UserSubmitForm
  type UserSubmitForm = {
    userid: string;
    password: string;
    rememberMe?: boolean;
  };
  
  //This using Yup liba to do the invaild feedback
  const validationSchema = Yup.object().shape({
    userid: Yup.string().required('UserID is required'),
    password: Yup.string().required('Password is required')
  });
  
  // calling the register and handleSubmit from hook
 const {
    formState: { errors }
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema)
  });
  
  /*---------------------------------------------------------authentication------------------------------------------*/
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  let initialValues: UserSubmitForm = {
    userid: "",
    password: ""
  }

  //handle the login btn
  const handleLogin = (formValue: UserSubmitForm) => {
    const { userid, password } = formValue;
    setMessage("");
    setLoading(true);
  
  //call the login API
    login(userid, password).then(
      (responsedata) => {
        if(responsedata.error){
          const resMessage = responsedata.error.toString();
          setMessage(resMessage);
          setLoading(false);
        }
        else{
          navigate("/");
          window.location.reload();
        }    
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      }
    );
  };
  /*---------------------------------------------------------render()-------------------------------------------------*/
      return (
      <div className="SignInbody">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleLogin}
            >
              <Form>
                <h3>Sign In</h3>
                <div className="mb-3">
                  <label htmlFor='userid'>User ID</label>
                  <Field
                    name = "userid"
                    className={`form-control ${errors.userid ? 'is-invalid' : ''}`} // Depends on the valid or invalid changed styled
                    type="text"
                    placeholder="Enter your UserID"
                  />
                  <ErrorMessage
                      name="userid"
                      component="div"
                      className="alert alert-danger"
                    />
                </div>
        
                <div className="mb-3">
                  <label htmlFor='password'>Password</label>
                  <Field
                    name = "password"
                    className={`form-control ${errors.password ? 'is-invalid' : ''}`} // Depends on the valid or invalid changed styled
                    type="password"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
        
                <div className="mb-3">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                    />
                    <label className="custom-control-label" htmlFor="customCheck1">
                      Remember me
                    </label>
                  </div>
                </div>
        
                <div className="d-grid">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                  </button>
                </div>
                <br/>
                <div style={{color:'#B33A3A'}}> Warning: You will be logged out if you login more than once with the same account </div>
                {/* <p className="forgot-password text-right">
                  <a href="/sign-up">Forgot Password?</a>
                </p> */}

                {message && (
                  <div className="mb-3">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      );
}


export default Login;